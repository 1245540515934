import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LookupService } from '@core/services/lookup.service';
import { CAR_CI_OF_FUEL, CAR_INSTRUMENT_CI_ID, CAR_LIQUID_COMPLIANCE_CREDITS_FLD, CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD, CAR_MANUAL_ENERGY_DENSITY, CAR_MANUAL_ENERGY_DENSITY_VALUE } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import { getAlphanumericIdInput, getCarbonIntensity } from '@module/credit-adjustment-report/routines/credit-adjustment-report.routine';
import { CCR_ADJUSTMENT_STEP_PREFIX, CCR_TRANSLATE_PREFIX, getInputField } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { CreditCreationReportsService } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { LocalPaginationService } from '@shared/services/local-pagination.service';
import { Dialog } from 'primeng';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-ccr-adjustments-under-section-view-input-details',
  templateUrl: './ccr-adjustments-under-section-view-input-details.component.html',
  styleUrls: ['./ccr-adjustments-under-section-view-input-details.component.scss'],
  providers: [LocalPaginationService],
})
export class CcrAdjustmentsUnderSectionViewInputDetailsComponent implements OnInit {
  @ViewChild('dialog', { static: false }) dialog: Dialog;
  public form = new FormGroup({});
  public fields: FormlyFieldConfig[];
  public model :any ={};

  public tableConfig: ITableViewConfig;
  public tableData = {content: []};
  visible = true;
  separatorMask = 'separator.5';
  commaSeparator = ',';
  public translationPrefix = `${CCR_TRANSLATE_PREFIX}${CCR_ADJUSTMENT_STEP_PREFIX}`;

  public alphanumericIds = [];

  @ViewChild('CheckboxColTemplate', {static: true}) public CheckboxColTemplate: TemplateRef<any>;
  @ViewChild('inputColTemplate', {static: true}) public inputColTemplate: TemplateRef<any>;
  @ViewChild('allocateVolColTemplate', {static: true}) public allocateVolColTemplate: TemplateRef<any>;
  @ViewChild('complianceQuantityTemplate', {static: true}) public complianceQuantityTemplate: TemplateRef<any>;
  @ViewChild('energyDensityTemplate', {static: true}) public energyDensityTemplate: TemplateRef<any>;
  @ViewChild('liquidComplianceCreditTemplate', {static: true}) public liquidComplianceCreditTemplate: TemplateRef<any>;
  @ViewChild('gaseousCompliacneCreditTemplate', {static: true}) public gaseousCompliacneCreditTemplate: TemplateRef<any>;
  @ViewChild('alphanumericIdTemplate', {static: true}) public alphanumericIdTemplate: TemplateRef<any>;
  public referenceCarbonIntensity: number;
  public referenceCIOfElectricity$ = new BehaviorSubject([]);

  public documentUploadConfig;

  private usedUnitsMap:any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CcrAdjustmentsUnderSectionViewInputDetailsComponent>,
    private formFactoryService: FormFactoryService,
    public service: CreditCreationReportsService,
    private lookupService: LookupService,
    private localPaginationService: LocalPaginationService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.translationPrefix = this.translationPrefix + `_${this.service.currentStep.code}`;
    if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
      this.setDocConfig();
      this.getAlphanumericIds();
    } else {
      this.setFields();
      this.setUsedUnitsMap();
      this.geReferenceCarbonIntensity(this.data);
      this.getReferenceCarbonIntensityForElectricity(this.data);
    }
    this.setTableConfig();
    this.setTableData();
  }

  setFields() {
    this.fields = this.formFactoryService.configureForm([
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getAlphanumericIdInput(),
          getCarbonIntensity('carbonIntensityView',CAR_CI_OF_FUEL)
        ]
      }
     ]);
  }

  setTableConfig() {
    let configName = '';
    if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
      configName = 'ANNUAL_STEP-SUB_SEC_9_3_DETAIL';
    } else {
      configName = this.data.rowData.ciModel.activityType === 'CI_CHARGING_STATIONS' ? 'CI_CHARGING_STATIONS_ACCR_SECTION_90' :  'ANNUAL_STEP-SUB_SEC_90_DETAIL';
    }

    this.lookupService.getUnderSectionTableConfig(configName).subscribe(result=>{
      if (this.service.currentStep.code === 'STEP-SUB_SEC_9_3') {
        this.configureSection_9_3_Cols(result);
      } else {
        this.configureSection_90_88_Cols(result);
      }
      this.scrollToTop();
    })
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  setTableData() {

    this.data.viewDetailsData.forEach(element => {
      element.quarterAndYear = `${element.nameOfQuarter}-${element.year}`
    });

    if(this.data.viewDetailsData.length){
      this.localPaginationService.data = this.data.viewDetailsData
      this.tableData = this.localPaginationService.getPage({ page: 0 });
    }
    this.model ={
      [CAR_INSTRUMENT_CI_ID] : this.data.viewDetailsData[0].referredReportDetail ? this.data.viewDetailsData[0].referredReportDetail.alphanumericId: this.data.viewDetailsData[0].alphanumericId,
      [CAR_CI_OF_FUEL]: this.data.viewDetailsData[0].referredReportDetail ? this.data.viewDetailsData[0].referredReportDetail.approvedCliValue: this.data.viewDetailsData[0].approvedCliValue
    }
  }

  onVolumneAllocation(data){
    data._quantityAvailableRaw  = data.totalComplianceCreditQuantity - (+data.complianceCreditQuantity + this.usedUnitsMap[data.referredReportDetailId]);
    data._quantityAvailable = Math.max(data._quantityAvailableRaw, 0);

    this.calculatedCredits(data);
  }

  calculatedCredits(data){
    if(data.step.code !== 'STEP-8'){
      //calculation of credits from step 1 to step 7 of CCR
      if (this.referenceCarbonIntensity) {

        const ciLiquidLowCIFuel =  this.data.rowData.ciModel.approvedCarbonIntensityRounded
        const vProducedLiquidLowCIFuel = Number(data.complianceCreditQuantity) || 0;
        const energyDensity = data[CAR_MANUAL_ENERGY_DENSITY] ? data[CAR_MANUAL_ENERGY_DENSITY_VALUE]||0 : data[CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD]||0;
        const calc = (this.referenceCarbonIntensity - ciLiquidLowCIFuel) * vProducedLiquidLowCIFuel * energyDensity * Math.pow(10, -6);
        data[CAR_LIQUID_COMPLIANCE_CREDITS_FLD]= Math.round(calc < 0 ? 0 : calc)

      }
    }
    else{
      // calculatoin of credits for step 8 (electricity/bio gas) of CCR
      const ciLiquidLowCIFuel =  this.data.rowData.ciModel.approvedCarbonIntensityRounded
      const vProducedLiquidLowCIFuel = Number(data.complianceCreditQuantity) || 0;
      const energyDensity = data[CAR_MANUAL_ENERGY_DENSITY] ? data[CAR_MANUAL_ENERGY_DENSITY_VALUE]||0 : data[CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD]||0;
      let carbonIntensityElecProducedUsingBiogas = ciLiquidLowCIFuel * vProducedLiquidLowCIFuel * energyDensity / data.electricalEnergyOutput

      const ciEnergyProduced = Number(carbonIntensityElecProducedUsingBiogas) || 0;
      const electricalEnergyProduced = Number(data.electricalEnergyProduced) || 0;
      let calc =0
      let fixedReferenceCarbonIntensity = this.referenceCarbonIntensity
      let referenceCarbonIntensityForElectricity = this.updateReferenceCIofElectricity(data)


      if(data.section=== 'SECTION_95'){
        calc = (Number(fixedReferenceCarbonIntensity) - ciLiquidLowCIFuel) * vProducedLiquidLowCIFuel * energyDensity * Math.pow(10, -6);
      }
      else{
        calc = (Number(referenceCarbonIntensityForElectricity) - ciEnergyProduced) * electricalEnergyProduced * Math.pow(10, -6);
      }
      data[CAR_LIQUID_COMPLIANCE_CREDITS_FLD] = Math.round(calc < 0 ? 0 : calc)

    }


  }

  public calculateTotalCredits(data) {
    data.totalComplianceCreditQuantity = (+data.liquidComplianceCredits || 0) + (+data.gaseousComplianceCredits || 0);
  }

  public onAlphanumericIdChange(event, data) {
    data.alphanumericId = event.value;
    if (event.dataId) {
      data.approvedCliId = event.value;
    } else {
      data.alphanumericCode = event.value;
    }

    data.approvedCliValue = event.fuelValue;
    data.carbonIntensityFuel = event.fuelValue;
    data.ciSectionCode = event.code;
  }

  geReferenceCarbonIntensity(data){
    this.service.getReferenceCarbonIntensityForFuel(this.service.currentData.complianceYear, data.rowData.ciModel.fuelDto.id)
        .subscribe(refCI => {
          if (!isNaN(refCI)) {
            this.model.referencedCarbonIntensity = refCI;
            this.referenceCarbonIntensity = refCI;
          } else {
            this.service.showErrorMessage('noRefCiFound').subscribe();
          }
        });
  }

  getReferenceCarbonIntensityForElectricity(data){
    if(data.action === 'INPUT'){
    let electricityStep = data.viewDetailsData.filter((rec)=>rec.step.code==="STEP-8")
    if (electricityStep.length && electricityStep.length > 0 && electricityStep[0].facility.civicAddress.province) {
      this.service.getReferencedCarbonIntensityForElectricity(electricityStep[0].facility.civicAddress.province).subscribe(result => {
        this.referenceCIOfElectricity$.next(result);
      });
    }
  }
  }

  private updateReferenceCIofElectricity(data){
    if(data.referenceCiOfElectricityCode && this.referenceCIOfElectricity$.getValue() &&
    this.referenceCIOfElectricity$.getValue().length){
      const refCI = this.referenceCIOfElectricity$.getValue().find(
        item => item.code === data.referenceCiOfElectricityCode,
      );
      if (refCI) {
        return  refCI.value;
      }
    }
  }



  onPaginationChanged(){

  }

  updateColumnsConfiguration(cols) {
    if (['CI_SPEC_LCA_MODEL', 'CI_REGUALTION_SIX'].includes(this.data.rowData.ciSectionCode)) {
      const totalComplianceCreditQuantity = {
        header: 'complianceCreditQuantity',
        width: AUTO_WIDTH_FOR_COLUMNS,
        field: 'totalComplianceCreditQuantity'
      }
      const allocateQuantity = {
        header: 'allocatedQuantity',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.allocateVolColTemplate,
      }
      const quantityAvailable = {
        header: 'quantityAvailable',
        width: AUTO_WIDTH_FOR_COLUMNS,
        field: '_quantityAvailable'
      }
      const index = cols.findIndex((col) => col.field === 'complianceCreditQuantity');
      if (index !== -1) {
        cols.splice(index, 1, totalComplianceCreditQuantity, allocateQuantity, quantityAvailable);
      }
    }
    return [...cols]
  }

  public save(){
    if (this.tableData.content.some(data=> data._quantityAvailableRaw < 0) && this.data.rowData.ciSectionCode === 'DEFAULT_CI') {
      return;
    }
    this.dialogRef.close(this.data);
  }

  public close(){
    this.dialogRef.close(false);
  }

  get headerText(){
    return this.data.action === 'VIEW' ?
    'CREDIT_ADJUSTMENT_REPORT.reportStep.viewDetailsTitle' : 'CREDIT_ADJUSTMENT_REPORT.reportStep.inputDetailsTitle'
  }

  private setUsedUnitsMap() {
    if (['CI_SPEC_LCA_MODEL', 'CI_REGUALTION_SIX'].includes(this.data.rowData.ciSectionCode)) {
      this.service.currentData.refReportDetails.forEach(refReport => {
        const defaultCIReports = this.service.currentData.reportDetails.filter(report => report.ciSectionCode === this.data.rowData.ciSectionCode && report.referredReportDetailId === refReport.id && report.ciApplicationId !==this.data.rowData.ciModel.ciApplicationId);
        this.usedUnitsMap[refReport.id] = defaultCIReports.reduce((acc, defaultCIReport) => (acc + (+defaultCIReport.complianceCreditQuantity|| 0)), 0);
      });

      this.updateAvailabelUnits();
    }
  }

  private updateAvailabelUnits() {
    this.data.viewDetailsData.forEach(data => {
      data._quantityAvailable = data.totalComplianceCreditQuantity - (+data.complianceCreditQuantity + this.usedUnitsMap[data.referredReportDetailId]);
      data._quantityAvailableRaw = data._quantityAvailable;
    });
  }

  private configureSection_90_88_Cols(configs) {
    this.tableConfig = configs;
    let _columns = [];
    const { columns, ...config } = this.tableConfig;
    _columns = [
      ...this.updateColumnsConfiguration(columns),
      {
        header: 'isOverride',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.CheckboxColTemplate,
      },
      {
        header: 'overriddenValue',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.inputColTemplate,
      },
    ];

    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };
  }

  private configureSection_9_3_Cols(configs) {
    this.tableConfig = configs;
    let _columns = [];
    const { columns, ...config } = this.tableConfig;

    _columns = [
      ...columns,
    ];
    const indexCI = _columns.findIndex((col) => col.field === 'carbonIntensityFuel');
    if (indexCI !== -1) {
      _columns.splice(indexCI+1, 0,
        {
          header: 'complianceCreditQuantity',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.complianceQuantityTemplate,
        },
        {
          header: 'energyDensity',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.energyDensityTemplate,
        },
        {
          header: 'liquidComplianceCredits',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.liquidComplianceCreditTemplate,
        },
        {
          header: 'gaseousComplianceCredits',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.gaseousCompliacneCreditTemplate,
        }
      )
    }

    const index = _columns.findIndex((col) => col.field === 'instrumentId');
    if (index !== -1) {
      _columns.splice(index+1, 0, {
        header: 'alphanumericId',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.alphanumericIdTemplate
      });
    }

    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };
  }

  private getAlphanumericIds() {
    this.service.getDefaultCIsForACCR({
      accountId: this.service.currentData.accountId,
      year: this.service.currentData.complianceYear,
      ccrId: this.service.currentData.id
    }).subscribe((result: any) => {
      this.alphanumericIds = result;
    })
  }

  private setDocConfig() {

    this.documentUploadConfig = {
      documentType: true,
      entityName: 'CREDIT_CREATION_REPORT_ANNUAL_STEP_6_3',
      id: this.data.rowData.ciModel.ciApplicationId,
      comment: true,
      statusColumn: true,
      pendingSubmissionStatus: true,
      disablePaginator: false,
      documentUploadConfigs: [{
        documentTypeValue: 20010,
        documentTypeCode: 'CREDIT_ADJUSTMENT_EVIDENCE',
        documentType: true,
        id: this.data.rowData.ciModel.ciApplicationId,
        entityName: 'CREDIT_CREATION_REPORT_ANNUAL_STEP_6_3',
      }],
    };
  }

}
