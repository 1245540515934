import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ReplaceMAO } from '@core/models/account.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { APP_TYPE, SUBMISSION_MANAGEMENT_ACTIONS, } from '@module/submission-management/const/submission-management.const';
import { CARBON_INTENSITY_ACTIONS } from '@module/carbon-intensity/const/carbon-intensity.const';
import { ERP_ACTIONS } from '@module/emissions-reduction-projects/emissions-reduction-projects.const';
export const PERMISSION_APPROVE_ACCOUNT = 'APPROVE_ACCOUNT';
export const PERMISSION_APPROVE_CFR_ACCOUNT = 'APPROVE_CFR_ACCOUNT';
export const PERMISSION_REVIEW_APPLICATION = 'REVIEW_APPLICATION2';
export const PERMISSION_ACCOUNT_DUAL_CONTROL_APPROVAL = 'ACCOUNT_DUAL_CONTROL_APPROVAL';
const CANCEL_APPLICATION_PREFIX = 'SUBMISSION_MANAGEMENT_MODULE.cancelApplication.';
const PERMISSION_DISCLOSE_COMMENTS = 'CREDIT_DISCLOSE_REMARKS';
export const CI_CANCEL_PREFIX = 'ciCancel';
export class CancelApplicationComponent {
    constructor(_location, formFactoryService, translate, cfrRegistrationService, submissionManagementService, store, modalService, activatedRoute, ciPathwayReportService) {
        this._location = _location;
        this.formFactoryService = formFactoryService;
        this.translate = translate;
        this.cfrRegistrationService = cfrRegistrationService;
        this.submissionManagementService = submissionManagementService;
        this.store = store;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.ciPathwayReportService = ciPathwayReportService;
        this.formGroup = new FormGroup({});
        this.model = new ReplaceMAO('CI_PATHWAY_CANCELLATION');
        this.editable = true;
        this.submission = false;
        this.loaded = false;
        this._destroy$ = new Subject();
        this.appType = APP_TYPE.CI;
        this.isCurrentRouteIsForCancel = true; // used to enable the form in-case we have id for mark-as-active, mark-as-invalid, mark-as-under-review workflows
        this.displayInvalidReasonsDropdown = false; // used to display reasons as dict-select instead of free textarea on different actions other than cancel
        this.ciInavlidReasons$ = new BehaviorSubject([]);
    }
    ngOnInit() {
        this.user = this.store.user;
        const state = window.history.state;
        if (state.data) {
            this.callReasonsListAPI(state.data);
            this.loadScreen(state.data);
        }
        else {
            this.activatedRoute.params.subscribe(params => {
                if (params.id) {
                    this.submissionManagementService.getApplicationBySubmissionReportId(params.id)
                        .pipe(takeUntil(this._destroy$))
                        .subscribe(data => {
                        this.activatedRoute.data.subscribe(routeData => {
                            const mapData = SUBMISSION_MANAGEMENT_ACTIONS[routeData['action']];
                            const payload = Object.assign({}, data, mapData);
                            payload.id = data[mapData.id];
                            payload.status = data[mapData.status];
                            payload._entityStatus = data.status;
                            this.callReasonsListAPI(payload);
                            this.loadScreen(payload);
                        });
                    });
                }
                else if (params.cancelId) {
                    this.activatedRoute.url
                        .pipe(takeUntil(this._destroy$), map(url => url[0].path.split('-').pop()), tap(appType => this.appType = appType), switchMap(appType => this.submissionManagementService.getApplicationById(params.cancelId, appType))).subscribe(response => {
                        this.activatedRoute.data.subscribe(routeData => {
                            const data = response.content[0];
                            const actionSet = this.appType === APP_TYPE.CI ? CARBON_INTENSITY_ACTIONS : ERP_ACTIONS;
                            const mapData = actionSet['VIEW_CANCELLATION'];
                            const payload = Object.assign({}, data, mapData);
                            payload.id = data[mapData.id];
                            payload.status = data[mapData.status];
                            this.callReasonsListAPI(payload);
                            this.loadScreen(payload);
                        });
                    });
                }
                else {
                    this.close();
                }
            });
        }
    }
    callReasonsListAPI(model) {
        if (model['entityAction']) {
            (['mark-as-invalid', 'invalid-ci'].includes(model['entityAction'])) && (this.displayInvalidReasonsDropdown = true);
            if (['mark-as-invalid', 'mark-as-active', 'mark-as-under-review', 'invalid-ci'].includes(model['entityAction'])) {
                this.isCurrentRouteIsForCancel = false;
                ['invalid-ci'].includes(model['entityAction']) && (this.isCurrentRouteIsForCancel = true);
                this.displayInvalidReasonsDropdown && this.ciPathwayReportService.getCIInvalidReasons().pipe(takeUntil(this._destroy$)).subscribe((res) => {
                    this.ciInavlidReasons$.next(res.map(data => {
                        data.id = data.code;
                        return data;
                    }));
                });
            }
        }
    }
    fetchInvalidRequestData() {
        if (this.hasApproveAction() && this.model.status === 'SUBMITTED') {
            this.editable = true;
        }
        else {
            this.editable = false;
            setTimeout(() => {
                this.formGroup.patchValue({ reason: this.model['ciInvalidReason'] ? this.model['ciInvalidReason'] : null });
                this.formGroup.disable();
            });
        }
        this.getFormFields();
    }
    loadScreen(pData) {
        this.inputData = pData;
        this.prefix = `SUBMISSION_MANAGEMENT_MODULE.${this.inputData.prefix || this.appType}Application.`;
        this.appPrefix = this.inputData.prefix;
        this.isCICancel = this.inputData.prefix === CI_CANCEL_PREFIX;
        this.model = pData;
        if (this.model.id && this.isCurrentRouteIsForCancel) {
            const callStatusChangeRequestDataApi = this.model['entityAction'] && ['view-under-review-request', 'view-activation-request', 'invalid-ci'].includes(this.model['entityAction']);
            const api = callStatusChangeRequestDataApi ? this.submissionManagementService.getCiRequestChangeData(this.model.id) : this.submissionManagementService.getReplaceRequest(this.model.id);
            api.subscribe(response => {
                this.isVbFlow = response.type === 'CI_PATHWAY_RELEASE_VB';
                this.model = Object.assign({}, response, this.model);
                if (this.hasApproveAction() && this.model.status === 'SUBMITTED') {
                    this.editable = true;
                }
                else {
                    this.editable = false;
                    setTimeout(() => this.formGroup.disable());
                }
                this.getFormFields();
            });
        }
        else {
            if (this.model['entityAction'] && ['invalid-ci'].includes(this.model['entityAction'])) {
                this.fetchInvalidRequestData();
                return;
            }
            const vbFlow = pData._entityStatus === 'READY_FOR_VERIFICATION';
            if (this.inputData.action === 'RELEASE' || this.inputData.action === 'CANCEL') {
                this.release = this.inputData.action;
            }
            else {
                this.release = undefined;
                this.submission = true;
                this.model.type = vbFlow ? `${this.model.type}_VB` : this.model.type;
            }
            this.editable = false;
            this.userType = vbFlow ? 'VB' : 'Admin';
            this.getFormFields();
        }
    }
    hasApproveAction() {
        return this.model.entityActionList && this.model.entityActionList.findIndex(t => (t.workflowAction === 'APPROVE' && this.isVbFlow)
            || t.workflowAction === 'REVIEW_REQUEST_CANCELLATION'
            || t.workflowAction === 'REVIEW_REQUEST_RELEASE') > -1;
    }
    close() {
        this._location.back();
    }
    doAction(action) {
        this.formFactoryService.markFormGroupDirty(this.formGroup);
        if (this.formGroup.valid) {
            this.model.action = action.toUpperCase();
            const actionLabel = this.inputData.actionLabel ? this.inputData.actionLabel :
                `${action.toLowerCase()}${this.inputData.prefix}`;
            const submissionType = this.isCICancel ?
                this.translate.instant(`COMMON.submissionTypeList.carbonIntensity`) :
                this.translate.instant(`COMMON.submissionTypeList.${this.model.submissionType}`);
            this.modalService
                .open(ServiceMessageComponent, {
                message: 'confirmationMessageApplication',
                metaDataList: [
                    this.translate.instant(`COMMON.actionsLabel.${actionLabel}`),
                    submissionType,
                    this.model.legalName,
                    this.translate.instant(`COMMON.actionsLabel.${this.userType}`)
                ],
                type: "WARNING" /* WARNING */,
            }, true, DEFAULT_DIALOG_CONFIG)
                .afterClosed()
                .subscribe((result) => {
                if (result) {
                    const submissionType = this.model.submissionType;
                    const payload = this.model;
                    payload.submissionType = this.model.submissionTypeCode;
                    if (!this.isCurrentRouteIsForCancel) {
                        payload.type = null;
                    }
                    this.submissionManagementService.doAction(this.inputData.uri, payload).subscribe(response => {
                        this.close();
                    }, (error) => {
                        this.model.submissionType = submissionType;
                    });
                }
            });
        }
    }
    getFormFields() {
        this.fields = this.formFactoryService.configureForm([
            {
                groupFields: false,
                fieldGroup: [
                    {
                        type: 'readonly-input',
                        className: 'col-12',
                        key: 'legalName',
                        label: `${CANCEL_APPLICATION_PREFIX}organizationName`,
                        disabled: true,
                        required: false,
                        id: 'organizationName',
                    },
                    {
                        type: 'readonly-input',
                        className: 'col-12',
                        key: 'submissionType',
                        label: `${CANCEL_APPLICATION_PREFIX}submissionType`,
                        disabled: true,
                        required: false,
                        translatePrefix: 'COMMON.submissionTypeList',
                        translate: true,
                        id: 'submissionType',
                    },
                    {
                        type: 'readonly-input',
                        className: 'col-12',
                        key: 'activityType',
                        label: `${CANCEL_APPLICATION_PREFIX}activityType`,
                        disabled: true,
                        required: false,
                        hideExpression: '!model.activityType',
                        id: 'activityType',
                    },
                    {
                        type: 'readonly-input',
                        className: 'col-12',
                        key: this.applicationId,
                        label: `${this.prefix}applicationID`,
                        disabled: true,
                        required: false,
                        id: 'applicationId',
                    },
                    Object.assign({ type: !this.displayInvalidReasonsDropdown ? 'textarea' : 'dict-select', className: 'col-12', key: 'reason', label: `${this.prefix}reason`, disabled: this.hasApproveAction(), required: true, id: 'reqReason' }, (this.displayInvalidReasonsDropdown && { source: this.ciInavlidReasons$, placeholder: 'TASK_TO_DO.carbonIntensityPathwayReportModal.selectItem' })),
                    {
                        type: 'textarea',
                        className: 'col-12',
                        key: 'adminRemark',
                        label: this.isVbFlow ? `${this.prefix}vbadminRemarks` : `${this.prefix}adminRemarks`,
                        disabled: false,
                        required: false,
                        hideExpression: (model, formState, field) => {
                            if (model.discloseComment || this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS) || this.isVbFlow) {
                                return false;
                            }
                            return true;
                        },
                        id: 'adminRemark',
                    },
                    {
                        className: 'col-12',
                        type: 'checkbox',
                        key: 'discloseComment',
                        label: `${CANCEL_APPLICATION_PREFIX}disclose`,
                        hideExpression: (model, formState, field) => {
                            if (this.hasApproveAction() && this.isVbFlow) {
                                return false;
                            }
                            return !this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS);
                        },
                        id: 'discloseComment',
                    },
                ],
            },
        ]);
        this.loaded = true;
    }
    get applicationId() {
        return this.appPrefix === CI_CANCEL_PREFIX ? 'instrumentId' : 'applicationId';
    }
}
