import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IRawFormBase } from '@core/models/raw-form.types';
import { StoreService } from '@core/store/store.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { getMBApprovedCiId, getMBCompliancePeriod, getMBFuelType, getOrganizationName } from '@module/material-balance-report/material-balance-consts/material-balance.const';
import { MaterialBalanceReportService } from '../material-balance-report.service';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';
import { ICreateMaterialBalanceReportPayload } from '@module/material-balance-report/material-balance-report.types';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-create-material-balance-report',
  templateUrl: './create-material-balance-report.component.html',
  styleUrls: ['./create-material-balance-report.component.scss'],
})
export class CreateMaterialBalanceReportComponent implements OnInit {

  public visible = true;
  public fields: FormlyFieldConfig[];
  public form = new FormGroup({});
  public model: any = {};

  public compliancePeriod$: BehaviorSubject<any> = new BehaviorSubject([]);
  public fuelTypes$: BehaviorSubject<any> = new BehaviorSubject([]);
  public approvedCIIds$: BehaviorSubject<any> = new BehaviorSubject([]);
  private _destroy$ = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateMaterialBalanceReportComponent>,
    private store: StoreService,
    private formFactoryService: FormFactoryService,
    private service: MaterialBalanceReportService,
    private router: Router,
    private helperService: HelperService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.getMBCompliancePeriod();
    this.getMBFuelTypes();
    this.getMBApprovedCIIds();
    this.fields = this.formFactoryService.configureForm(this.getFormFields());
  }

  get payload(): ICreateMaterialBalanceReportPayload {
    return {
      accountId: this.model.accountId,
      carbonIntensityId: this.form.get('carbonIntensityId').value,
      complianceYear: this.model.compliancePeriod,
      fuelId: this.model.fuelId,
      fuelCode: this.model.fuelCode,
      fuelType: this.model.fuelType,
      fuelTypeFR: this.model.fuelTypeFR,
    };
  }

  getFormFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getOrganizationName(() => !this.store.user.isAdmin, event => this.onOrgChange(event)),
          getMBCompliancePeriod(this.compliancePeriod$, event => this.onComplianceYearChange(event)),
          getMBFuelType(this.fuelTypes$, event => this.onFuelChange(event)),
          getMBApprovedCiId(this.approvedCIIds$, event => this.onCIIdChange(event)),
        ],
      }];
  }

  public create(): void {
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    if (this.form.valid) {
      this.service.createMBReport(this.payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(resp => {
          this.close();
          this.router.navigate(this.helperService.getTranslatedPath('/material-balance/report'), {state: {data: resp}});
        });
    }
  }

  public close() {
    this.visible = false;
    this.dialogRef.close();
  }

  private getMBCompliancePeriod() {
    this.service.getMBCompliancePeriod().pipe(
      tap(data => this.compliancePeriod$.next(data)),
    ).subscribe();

  }

  private getMBFuelTypes() {
    if (!this.model.accountId || !this.model.compliancePeriod) {
      return;
    }
    this.service.getMBFuelTypes(this.model).pipe(
      tap(data => {
        // if (data.length === 0) {
        //   this.resetControl('fuelId');
        // }
        this.fuelTypes$.next([...data]);
      }),
    ).subscribe();
  }

  private getMBApprovedCIIds() {
    if (!this.model.accountId) {
      return;
    }
    this.service.getMBApprovedCIIds(this.model).pipe(
      tap(data => {
        if (data.length === 0) {
          this.resetControl('carbonIntensityId');
        }
        this.approvedCIIds$.next([...data]);
    }),
    ).subscribe();
  }

  private onOrgChange(event) {
    if (event.event && event.event.initial) {
      return;
    }
    this.resetControl('fuelId');
    this.resetControl('carbonIntensityId');
  }

  private onComplianceYearChange(event) {
    if (event.event && event.event.initial) {
      return;
    }
    this.resetControl('fuelId');
    this.resetControl('carbonIntensityId');
    this.fuelTypes$.next([]);
    this.getMBFuelTypes();
    this.getMBApprovedCIIds();
  }

  private onCIIdChange(event) {
    if (event.event && event.event.initial) {
      return;
    }
    // if (!this.model.fuelCode) {
    this.getMBFuelTypes();
    // }
  }

  private onFuelChange(event) {
    // if (event.event && event.event.initial) {
    //   console.log('intraa');
    //   this.model.fuelCode = null;
    //   this.model.fuelType = null;
    //   this.model.fuelTypeFR = null;
    //   return;
    // }
    if (event.event && !event.event.initial) {
      this.model.fuelCode = event.event.code;
      this.model.fuelType = event.event.fuelEn;
      this.model.fuelTypeFR = event.event.fuelFr;
    }
    // this.resetControl('carbonIntensityId');
    this.getMBApprovedCIIds();
  }

  private resetControl(controlname: string) {
    const control = this.form.get(controlname);
    control && control.reset();
  }

}
