<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  (onShow)="scrollToTop()"
  [header] = "headerText | translate">

  <ng-container *ngIf="service.currentStep.code !== 'STEP-SUB_SEC_9_3'; else SECTION_9_3">
    <h3 class="m-0 mt-4 pb-2"> {{translationPrefix + '.viewDetailsHeading' | translate}} </h3>

    <form [formGroup]="form">
      <formly-form [form]="form" [model]="model"  [fields]="fields"></formly-form>
    </form>
  </ng-container>

  <ng-template #SECTION_9_3>
    <h3 class="m-0 mt-4 pb-2"> {{translationPrefix + '.viewDetailsHeading_Sec_9_3' | translate}} </h3>
  </ng-template>


  <div class="table-area table1">
    <app-table-view
      [data]="tableData"
      [config]="tableConfig"
    >
    </app-table-view>
  </div>

  <div class="document-container" *ngIf="documentUploadConfig">
    <app-documents
      [documentUploadConfig]="documentUploadConfig"
      [showPlaceholders]="true"
      [relative]="true"
      [disabled]="service.disabled">
    </app-documents>
  </div>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" disableOnUpload class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
    <button type="button"
            disableOnUpload
            class="btn btn-primary"
            (click)="save()">
      {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
    </button>
  </div>
</p-dialog>

<ng-template #CheckboxColTemplate let-data>
  <input
  #inputEl
  [(ngModel)]="data.overrideComplianceCredits"
  class="checkbox-align"
  type="checkbox"
/>
</ng-template>

<ng-template #inputColTemplate let-data>
<input
*ngIf="data.overrideComplianceCredits"
#inputEl
[allowNegativeNumbers]="false"
[maxlength]="'19'"
[max]="data.quantity"
[mask]="'separator.5'"
[thousandSeparator]="commaSeparator"
[(ngModel)]="data.complianceCreditsCreated"
[disabled]="isDisabled || data.disabled"
class="form-control"
type="text"
/>

<div class="invalid-feedback d-block"
   *ngIf="!data.overrideComplianceCredits">
<span class="table-action-text">N/A</span>
</div>
</ng-template>

<ng-template #allocateVolColTemplate let-data>
<input

#inputEl
[allowNegativeNumbers]="false"
[maxlength]="'19'"
[max]="data.quantity"
[mask]='separatorMask'
[thousandSeparator]="commaSeparator"
[(ngModel)]="data.complianceCreditQuantity"
[disabled]="isDisabled || data.disabled"
[class.is-invalid]="!isDisabled && !data.disabled && data._quantityAvailableRaw < 0"
class="form-control"
(change)="onVolumneAllocation(data)"
type="text"
/>

<div
class="invalid-feedback d-block"
*ngIf="data._quantityAvailableRaw < 0">
<span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
</div>

</ng-template>


<ng-template #complianceQuantityTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.complianceCreditQuantity"
  [disabled]="isDisabled"
  [class.is-invalid]="!isDisabled && data.complianceCreditQuantity < 0"
  class="form-control"
  type="text"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.complianceCreditQuantity < 0">
  <span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
  </div>

</ng-template>

<ng-template #energyDensityTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.energyDensity"
  [disabled]="isDisabled"
  [class.is-invalid]="!isDisabled && data.energyDensity < 0"
  class="form-control"
  type="text"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.energyDensity < 0">
  <span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
  </div>

</ng-template>

<ng-template #liquidComplianceCreditTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.liquidComplianceCredits"
  [disabled]="isDisabled"
  [class.is-invalid]="!isDisabled && data.liquidComplianceCredits < 0"
  class="form-control"
  (change)="calculateTotalCredits(data)"
  type="text"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.liquidComplianceCredits < 0">
  <span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
  </div>

</ng-template>
<ng-template #gaseousCompliacneCreditTemplate let-data>
  <input

  #inputEl
  [allowNegativeNumbers]="false"
  [maxlength]="'19'"
  [max]="data.quantity"
  [mask]='separatorMask'
  [thousandSeparator]="commaSeparator"
  [(ngModel)]="data.gaseousComplianceCredits"
  [disabled]="isDisabled"
  [class.is-invalid]="!isDisabled && data.gaseousComplianceCredits < 0"
  class="form-control"
  (change)="calculateTotalCredits(data)"
  type="text"
  />

  <div
  class="invalid-feedback d-block"
  *ngIf="data.gaseousComplianceCredits < 0">
  <span>{{ 'COMMON.messageSection.compRepQuantityExceeded' | translate }}</span>
  </div>

</ng-template>

<ng-template #alphanumericIdTemplate let-data let-rowIndex="rowIndex">
  <ng-select
      wcag-label
      [placeholder]="'COMMON.ciApplicationTypeList.selectItem' | translate"
      [inputAttrs]="{'aria-label': 'COMMON.ciApplicationTypeList.selectItem' | translate}"
      [items]="alphanumericIds"
      class="ng-dropdown-left"
      bindLabel="name"
      bindValue="value"
      [(ngModel)]="data.alphanumericId"
      (change)="onAlphanumericIdChange($event, data)"
      [appendTo]="'.ui-table-wrapper table'"
      [alignRight]="true"
      clearable="false"
      [readonly]="service.disabled">
    </ng-select>
</ng-template>
