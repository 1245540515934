import { HttpParams } from '@angular/common/http';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { environment } from '@env/environment';
import { CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY, CREDIT_BALANCE_REPORT_DATA_KEY, } from '@module/credit-balance/credit-balance-report.const';
import { MATERIAL_BALANCE_REPORT_PREFIX, MATERIAL_BALANCE_REPORT_STEP_PREFIX, MATERIAL_BALANCE_STEP_COMPONENT_MAP, } from '@module/material-balance-report/material-balance-consts/material-balance.const';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { Subject } from 'rxjs';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/modal.service";
import * as i3 from "../../shared/services/helper.service";
import * as i4 from "@angular/router";
export const GET_MB_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuel';
export const GET_MB_COMPLIANCE_YEAR = '/cfr-service/materialBalanceReport/getMBRComplianceYearList';
export const GET_MB_REPORT_BY_ID = '/cfr-service/materialBalanceReport/';
export const GET_MB_CI_IDS = '/cfr-service/materialBalanceReport/getMBRCIIdList';
export const GET_MB_FUEL_LIST = '/cfr-service/materialBalanceReport/getMBRFuelList';
export const GET_MB_TABLE_DATA = '/cfr-service/materialBalanceReport/pageSearchByFilter';
export const CREATE_MB_REPORT = '/cfr-service/materialBalanceReport/create';
export const SAVE_MB_REPORT = '/cfr-service/materialBalanceReport/save';
export const PROPOSE_MB_REPORT = '/cfr-service/materialBalanceReport/propose';
export const DISCARD_MB_REPORT = '/cfr-service/materialBalanceReport/discard';
export const GET_RESUBMIT_REPORT = '/cfr-service/materialBalanceReport/reportToResubmit/';
export const MB_DOWNLOAD_REPORT = '/cfr-service/materialBalanceReport/download/report/';
export const MB_SEARCH_ORG = '/account-service/cfrAccount/cfrDetailForMBR';
export const GET_RECONCILIATION_DATA = '/cfr-service/materialBalanceReport/viewReconciliationPageSearchByFilter';
export class MaterialBalanceReportService extends BaseStepperService {
    constructor(http, modalService, helperService, router) {
        super();
        this.http = http;
        this.modalService = modalService;
        this.helperService = helperService;
        this.router = router;
        this.updateSteps$ = new Subject();
        this.translationPrefix = `${MATERIAL_BALANCE_REPORT_PREFIX}${MATERIAL_BALANCE_REPORT_STEP_PREFIX}`;
        this.currentStepInvalid = false;
    }
    createMBReport(payload) {
        return this.http.post(`${environment.apiUrl}${CREATE_MB_REPORT}`, payload);
    }
    getMBCompliancePeriod() {
        return this.http.get(`${environment.apiUrl}${GET_MB_COMPLIANCE_YEAR}`);
    }
    getMBFuelTypes(payload) {
        return this.http.post(`${environment.apiUrl}${GET_MB_FUEL_LIST}`, payload);
    }
    getMBApprovedCIIds(payload) {
        return this.http.post(`${environment.apiUrl}${GET_MB_CI_IDS}`, payload);
    }
    getTableData(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${GET_MB_TABLE_DATA}`, filterState, { params });
    }
    getReportById(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_MB_REPORT_BY_ID}${reportId}`);
    }
    getReportByIdToReSubmit(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_RESUBMIT_REPORT}${reportId}`);
    }
    getFuelEnergyDensity(fuelId) {
        return this.http.get(`${environment.apiUrl}${GET_MB_ENERGY_DENSITY_URI}/${fuelId}`);
    }
    saveReport(payload) {
        return this.http.post(`${environment.apiUrl}${SAVE_MB_REPORT}`, Object.assign({}, payload, { action: 'SAVE', saveAndProceed: false }));
    }
    saveAndProceed(payload) {
        return this.http.post(`${environment.apiUrl}${SAVE_MB_REPORT}`, Object.assign({}, payload, { action: 'SAVE', saveAndProceed: true }));
    }
    discard(payload) {
        return this.http.post(`${environment.apiUrl}${DISCARD_MB_REPORT}`, payload);
    }
    submitReport(payload) {
        return this.http.post(`${environment.apiUrl}${SAVE_MB_REPORT}`, Object.assign({}, payload, { saveAndProceed: false }));
    }
    proposeReport(payload) {
        return this.http.post(`${environment.apiUrl}${PROPOSE_MB_REPORT}`, payload);
    }
    getViewReconciliationData(pagination, reportId) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        const filterState = {
            materialBalanceReportId: reportId
        };
        return this.http.post(`${environment.apiUrl}${GET_RECONCILIATION_DATA}`, filterState, { params });
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.currentData));
        sessionStorage.setItem(CREDIT_BALANCE_REPORT_DATA_KEY, data);
        const step = JSON.stringify(Object.assign({}, this.currentStep));
        sessionStorage.setItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY, step);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(CREDIT_BALANCE_REPORT_DATA_KEY);
        if (data) {
            !skipRemove && sessionStorage.removeItem(CREDIT_BALANCE_REPORT_DATA_KEY);
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    restoreLastStep() {
        const currentStep = sessionStorage.getItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
        if (currentStep) {
            sessionStorage.removeItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
            return JSON.parse(currentStep);
        }
        else {
            return null;
        }
    }
    setReportSteps(omitStep = false) {
        const steps = this.currentData.associatedSteps.map((step, index) => {
            return this.getStepContent(step, index);
        });
        this.steps = [...steps];
        return [...steps];
    }
    getStepContent(step, index) {
        const component = MATERIAL_BALANCE_STEP_COMPONENT_MAP[step.code];
        const _step = Object.assign({}, step, { name: `.${step.code}_Name`, completed: false, id: index + 1, component });
        _step.id = index + 1;
        return _step;
    }
    openWarningPopUp(message, metaDataList = []) {
        return this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message,
            metaDataList,
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    showError(message, transPrefix) {
        const messages = {
            messages: [{ message }],
            transPrefix,
            type: "ERROR" /* ERROR */,
        };
        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe();
    }
    downloadDocument(documentId) {
        const uri = environment.serviceUrl + MB_DOWNLOAD_REPORT + documentId;
        this.http.get(uri, { observe: 'response', responseType: 'blob' })
            .subscribe((response) => downloadBlob(response), () => this.http.get(uri, { observe: 'response' }).subscribe());
    }
    triggerAction(metadata, redirect = false) {
        const action = metadata.action;
        const payload = metadata.data;
        if (['VIEW', 'EDIT', 'SUBMIT_TO_ECCC_VERIFICATION_REPORT', 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT'].includes(action.workflowAction)) {
            this.router.navigate(this.helperService.getTranslatedPath(`/material-balance/report/${payload.id}`));
        }
        else if (action.workflowAction === 'RESUBMIT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/material-balance/report/${payload.id}/RESUBMIT`));
        }
        else if (action.workflowAction === 'DOWNLOAD_MATERIAL_BALANCE_REPORT') {
            this.downloadDocument(payload.id);
        }
        else if (action.workflowAction === 'VIEW_VERIFICATION_DETAILS') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/${payload.id}`));
        }
        else if (action.workflowAction === 'VIEW_VERIFICATION_REPORT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
        }
    }
    getOrganizationDetail(id = null, accountId) {
        const payload = {
            id,
            accountId,
            discriminatorType: 'REGISTRATION_ACCOUNT',
        };
        return this.http.post(`${environment.apiUrl}${MB_SEARCH_ORG}`, payload).
            pipe(tap(response => this.registrationReportsDetail = response));
    }
}
MaterialBalanceReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MaterialBalanceReportService_Factory() { return new MaterialBalanceReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.HelperService), i0.ɵɵinject(i4.Router)); }, token: MaterialBalanceReportService, providedIn: "root" });
